body {
  width: 100%;
}
h1 {
  margin: 20px 10px;
}
p.generic {
  margin: 20px 50px;
}

.kindle_book {
  width: 100%;
  margin: 10px auto;
}
.kindle_book {
  display: flex;
  flex-direction: row;
}
.kindle_book .image_div {
  margin: 5px auto;
}
.kindle_book img {
  width: 200px;
  margin: 5px auto;
}
.kindle_book .card {
  margin: 10px;
}
.kindle_book .card-text {
  font-size: 20px;
}
